import model from './model';
import { IHttpClient } from '@wix/yoshi-flow-editor';
import { queryAvailability } from '@wix/ambassador-bookings-availability-v1-slot-availability/http';
import { QueryAvailabilityRequest } from '@wix/ambassador-bookings-availability-v1-slot-availability/types';
import {
  ServicesCatalogServer,
  GetBusinessRequest,
} from '@wix/ambassador-services-catalog-server/http';

import {
  biReportWidgetLoaded,
  biReportTimePickerLoaded,
  biReportBookingsUouSeeMoreDatesTimesClick,
  biReportWidgetLoadedEmpty,
} from '../../biEvents';
import { components } from './components';
import { images } from './images';

const CATALOG_READER_BASE_URL = '/_api/services-catalog';
let allServices: any[] = [];
let availability;
let businessLocaleData: {
  businessLocale: string | undefined;
  userLocale: string;
  preferredLocale: string;
} = {
  businessLocale: 'America/New_York',
  userLocale: 'America/New_York',
  preferredLocale: 'business',
};
export default model.createController((controllerParams) => {
  let selectedService;
  let userLocale = 'America/New_York';
  const { $widget, flowAPI, $w, controllerConfig } = controllerParams;
  const t = flowAPI.translations.t;
  const biLogger = flowAPI.essentials.biLoggerFactory().logger();

  console.log('UOU | controllerParams:', controllerParams);
  const query = controllerParams?.controllerConfig?.wixCodeApi?.location?.query
  console.log('UOU | query:', query);
  
  let env = flowAPI.environment;
  // Workaround for TB-9985
  if (query?.commonConfig != undefined && query.commonConfig.includes('studio')) {
    env.isEditorX = true;
    env.isEditor = false;
  }
  console.log('UOU | env:', env);

  if (env.isClassicEditor || env.isEditor || env.isEditorX) {
    // console.log('UOU | Editor mode');
    $w(components.multiStateBox).changeState('main');
    $w(components.availabilityStateBox).changeState('service');
  } else if (env.isViewer && env.isSSR) {
    // console.log('UOU | Viewer SSR mode');
    $w(components.multiStateBox).changeState('main');
    $w(components.availabilityStateBox).changeState('loader');
  } else {
    // console.log('UOU | live mode, nothing to do.');
  }
  $widget.onPropsChanged((oldProp, newProp) => {
    // console.log('UOU | All presets | oldProp:', oldProp);
    // console.log('UOU | All presets | newProp:', newProp);
    $w(components.serviceImage).imageShape = newProp.imageShape;
    $w(components.serviceImage).layout = newProp.layout;
    const updateService = oldProp.serviceId
      .toString()
      .includes(newProp.serviceId.toString())
      ? false
      : true;
    const updateLocation = oldProp.locationId
      .toString()
      .includes(newProp.locationId.toString())
      ? false
      : true;
    if (
      oldProp.layout !== newProp.layout ||
      oldProp.numberOfSlots !== newProp.numberOfSlots ||
      oldProp.mobileNumberOfSlots !== newProp.mobileNumberOfSlots ||
      !oldProp.serviceId.toString().includes(newProp.serviceId.toString()) ||
      !oldProp.locationId.toString().includes(newProp.locationId.toString())
    ) {
      // console.log('UOU | Need to updateService:', updateService);
      // console.log('UOU | Need to updateLocation:', updateLocation);
      updateWidgetData(newProp, updateService, updateLocation);
    }
  });

  const updateWidgetData = async (props, updateService, updateLocation) => {
    // console.log('UOU | updateWidgetData is running');
    if (updateService || updateLocation) {
      // console.log('UOU | Need to update service to:', props.serviceId);
      selectedService = await getServiceV2ById(flowAPI, props.serviceId);
    }
    // console.log('UOU | Updating widget to props:', props);
    // console.log('UOU | props.locationId:', props.locationId);

    if (
      (props.locationId.includes('CUSTOM') &&
        selectedService?.locations[0].type.includes('CUSTOM')) ||
      (props.locationId === 'BUSINESS' &&
        selectedService?.locations[0].type === 'BUSINESS')
    ) {
      // console.log('UOU | CUSTOM Location match!');
      updateWidgetInfo(props);
    } else if (
      selectedService?.locations[0].type === 'BUSINESS' &&
      props.locationId !== 'CUSTOM' &&
      selectedService?.locations.filter(
        (location) => location.business.id === props.locationId,
      ).length > 0
    ) {
      updateWidgetInfo(props);
    } else if (selectedService == undefined) {
      // console.log('UOU | dummy data state');
      // setDummyDataAvailability(props);
    } else {
      // console.log('UOU | locationId missmatch :(');
    }
    // console.log('UOU | Done Widget Data');
  };

  const updateWidgetInfo = async (props) => {
    const updatedAvailability = await getSlotsAvailability(
      flowAPI.httpClient,
      controllerConfig.appParams.instance,
      props,
      businessLocaleData,
      selectedService.type === 'APPOINTMENT'
        ? selectedService.staffMemberIds.length
        : 1,
      flowAPI.environment.isMobile,
    );

    const mainRepeaterData = prepareRepeaterData(
      updatedAvailability,
      flowAPI.environment.isMobile
        ? props.mobileNumberOfSlots
        : props.numberOfSlots,
      businessLocaleData,
    );
    // console.log('UOU | updateWidgetData mainRepeaterData:', mainRepeaterData);
    if (!env.isSSR) {
      userLocale =
        navigator.languages && navigator.languages.length
          ? navigator.languages[0]
          : navigator.language;
    }
    // console.log('UOU | userLocale:', userLocale);

    populateServiceData(
      $w,
      flowAPI,
      selectedService,
      businessLocaleData,
      props,
      biLogger,
    );

    populateWidgetData(
      $w,
      flowAPI,
      selectedService,
      mainRepeaterData,
      userLocale,
      biLogger,
    );
  };

  const translateWidgetElements = (flowAPI) => {
    $w(components.noServicesText).text = t('app.widget.noServices');
    $w(components.vcBadge).setTranslation(t('app.widget.availableOnline'));
    $w(components.bookYourNextSession).text = t('app.widget.bookNextSession');
    $w(components.seeMoreDatesAndTimes).label = t('app.widget.seeMoreDatesAndTimes');
    $w(components.noFutureAvailabilityText).text = t('app.widget.noServiceAvailability');
  }

  return {
    pageReady: async () => {

      translateWidgetElements(flowAPI);

      if (!env.isSSR) {
        userLocale =
          navigator.languages && navigator.languages.length
            ? navigator.languages[0]
            : navigator.language;
        businessLocaleData = await getLocaleFromBusinessData(
          controllerConfig.appParams.instance,
        );
        // console.log('UOU | businessLocaleData:', businessLocaleData);
      }

      

      // console.log('env:', env)
      if (env.isEditor) {
        $w(components.serviceImage).setImage(images.classic);
      } else if (env.isEditorX) {
        $w(components.serviceImage).setImage(images.studio);
      }

      const props = $widget.props;
      // console.log('UOU | pageReady props:', props);

      const serviceId: String = String(props?.serviceId);
      // console.log('pageReady serviceId:', serviceId);

      let mainRepeaterData: any[] = [];

      if (serviceId.includes('undefined')) {
        // console.log('UOU | No service defined by prop');
        allServices = await queryFirstService(flowAPI);
        // console.log('UOU | Found services:', allServices);
        if (allServices !== undefined && allServices.length === 0) {
          // console.log('UOU | No prop and no services');
          if (flowAPI.environment.isViewer) {
            $w(components.multiStateBox).changeState('noServices');
          }
        } else if (allServices !== undefined && allServices.length !== 0) {
          // // console.log(
          //   'UOU | No serviceId prop but services found, showing the first service',
          // );
          selectedService = allServices[0];
          // console.log('UOU | Setting selected service to:', selectedService);
          props.serviceId = selectedService.id;
          props.locationId = selectedService.locations[0].business
            ? selectedService.locations[0].business.id
            : selectedService.locations[0].type;
          // console.log('UOU | Updated props:', props);
          populateServiceData(
            $w,
            flowAPI,
            allServices[0],
            businessLocaleData,
            props,
            biLogger,
          );
          // Show availability based on first location

          availability = await getSlotsAvailability(
            flowAPI.httpClient,
            controllerConfig.appParams.instance,
            props,
            businessLocaleData,
            selectedService.type === 'APPOINTMENT'
              ? selectedService.staffMemberIds.length
              : 1,
            flowAPI.environment.isMobile,
          );
          // console.log('UOU | Availability:', availability);

          mainRepeaterData = prepareRepeaterData(
            availability,
            flowAPI.environment.isMobile
              ? props.mobileNumberOfSlots
              : props.numberOfSlots,
            businessLocaleData,
          );
          // console.log('UOU | mainRepeaterData:', mainRepeaterData);

          populateWidgetData(
            $w,
            flowAPI,
            allServices[0],
            mainRepeaterData,
            userLocale,
            biLogger,
          );
        }
      } else {
        // console.log('UOU | Service defined by prop:', serviceId);
        // console.log('UOU | allServices:', allServices);
        selectedService = await getServiceV2ById(flowAPI, serviceId);
        // console.log('UOU | selectedService:', selectedService);
        if (selectedService.hidden) {
          // console.log('UOU | Found serviceId but no services');
          if (!env.isSSR && flowAPI.environment.isViewer) {
            $w(components.multiStateBox).changeState('noServices');
          }
          // Show dummy data - do nothing
        } else {
          // console.log('UOU | Found serviceId and found services');
          // Populate service
          populateServiceData(
            $w,
            flowAPI,
            selectedService,
            businessLocaleData,
            props,
            biLogger,
          );
          if (!env.isSSR) {
            availability = await getSlotsAvailability(
              flowAPI.httpClient,
              controllerConfig.appParams.instance,
              props,
              businessLocaleData,
              selectedService.type === 'APPOINTMENT'
                ? selectedService.staffMemberIds.length
                : 1,
              flowAPI.environment.isMobile,
            );

            mainRepeaterData = prepareRepeaterData(
              availability,
              flowAPI.environment.isMobile
                ? props.mobileNumberOfSlots
                : props.numberOfSlots,
              businessLocaleData,
            );
            // console.log('UOU | mainRepeaterData:', mainRepeaterData);
          } else {
            $w(components.availabilityStateBox).changeState('loader');
          }
          populateWidgetData(
            $w,
            flowAPI,
            selectedService,
            mainRepeaterData,
            userLocale,
            biLogger,
          );
        }
      }
      if (!env.isSSR) {
        $widget.fireEvent('widgetLoaded', {});
        biReportWidgetLoaded(biLogger, controllerParams.flowAPI.environment);
        biReportTimePickerLoaded(
          biLogger,
          calculateNumberOfSlotsFromMainRepeaterData(mainRepeaterData),
        );
      }

      $w(components.seeMoreDatesAndTimes).onClick(() => {
        // console.log('UOU | clicked: seeMoreDatesAndTimes');
        biReportBookingsUouSeeMoreDatesTimesClick(biLogger);
      });
    },
    exports: {},
  };
});

export const timezoneToShow = (businessLocaleData) => {
  const selectedTimezone =
    businessLocaleData.preferredLocale === 'business'
      ? businessLocaleData.businessLocale
      : businessLocaleData.userLocale;
  let stringToreturn;
  const longTimezone = new Date()
    .toLocaleDateString('en-US', {
      timeZone: selectedTimezone,
      timeZoneName: 'long',
    })
    .split(',')[1];
  // console.log('UOU | longTimezone', longTimezone);

  const shortTimezone = new Date()
    .toLocaleDateString('en-US', {
      timeZone: selectedTimezone,
      timeZoneName: 'short',
    })
    .split(',')[1]
    .trim();
  // console.log('UOU | shortTimezone', shortTimezone);

  if (businessLocaleData.preferredLocale === 'business') {
    stringToreturn = `${longTimezone} (${shortTimezone})`;
  } else {
    const timeOffset = (new Date().getTimezoneOffset() * -1) / 60;
    // console.log(timeOffset);
    if (timeOffset > 0) {
      stringToreturn = `${longTimezone} (GMT +${timeOffset})`;
    } else if (timeOffset < 0) {
      stringToreturn = `${longTimezone} (GMT -${timeOffset})`;
    } else {
      stringToreturn = `${longTimezone} (GMT -${timeOffset})`;
    }
  }

  // console.log('UOU | timezone:', stringToreturn);
  return stringToreturn;
};

export const getSlotsAvailability = async (
  httpClient: IHttpClient,
  authorization: String,
  props,
  businessLocaleData,
  numberOfResources = 1,
  isMobile = false,
) => {
  const filterBuilder = () => {
    // console.log('UOU | Calculating availability for Mobile:', isMobile);
    const timezoneForDate =
      businessLocaleData.preferredLocale === 'business'
        ? businessLocaleData.businessLocale
        : businessLocaleData.userLocale;
    const dateFrom = new Date(
      new Date().toLocaleString('en', { timeZone: timezoneForDate }),
    );
    // console.log('UOU | props before filterObject', props);
    const filterObject = {
      serviceId: [props.serviceId],
      startDate: dateFrom.toISOString(),
      endDate: new Date(
        new Date().setMonth(new Date().getMonth() + 6),
      ).toISOString(),
      bookable: true,
    };
    // console.log('UOU | filterObject:', filterObject);

    if (!props.locationId.includes('CUSTOM')) {
      // Relevant for CUSTOM and CUSTOMER locations
      // console.log('UOU | Business location found');
      if (!props.locationId.includes('BUSINESS')) {
        filterObject['location.businessLocation.id'] = [props.locationId];
      } else {
        // console.log('UOU | ... but not configured');
      }
    } else {
      // console.log('UOU | Custom location found');
    }
    return filterObject;
  };

  const queryAvailabilityRequest: QueryAvailabilityRequest = {
    query: {
      filter: filterBuilder(),
    },
    slotsPerDay: isMobile
      ? props.mobileNumberOfSlots * numberOfResources
      : props.numberOfSlots * numberOfResources,
    timezone:
      businessLocaleData.preferredLocale === 'business'
        ? businessLocaleData.businessLocale
        : businessLocaleData.userLocale,
  };
  // console.log('UOU | queryAvailabilityRequest:', queryAvailabilityRequest);
  const availability = await httpClient.request(
    queryAvailability(queryAvailabilityRequest),
  );
  return availability.data;
};

const getServiceV2ById = async (flowAPI, serviceId) => {
  let responseService;
  try {
    const response = await flowAPI.httpClient.get(
      `/_api/bookings/v2/services/${serviceId}`,
    );
    responseService = await response.data.service;
  } catch (e) {
    console.error('Catalog data error: ', e);
  }
  // console.log('UOU | getServiceV2ById responseService:', responseService);
  return responseService;
};

const getLocaleFromBusinessData = async (authorization) => {
  const servicesCatalogServer = ServicesCatalogServer(
    `${CATALOG_READER_BASE_URL}`,
  );
  const servicesCatalogService = servicesCatalogServer.BusinessCatalog();
  const getBusinessRequest: GetBusinessRequest = {
    suppressNotFoundError: false,
  };

  const business = await servicesCatalogService({
    Authorization: authorization,
  }).get(getBusinessRequest);
  // console.log('UOU | business:', business);
  const preferredLocale = business?.businessProperties?.customProperties?.find(
    (element) => element.propertyName == 'defaultTimezone',
  )?.value;
  const businessLocale = business.info?.timeZone;
  return {
    businessLocale,
    userLocale: Intl.DateTimeFormat().resolvedOptions().timeZone,
    preferredLocale: preferredLocale ? preferredLocale : 'business',
  };
};

const queryFirstService = async (flowAPI) => {
  let responseServices = [];
  try {
    const response = await flowAPI.httpClient.post(
      `/_api/bookings/v2/services/query`,
      {
        includeDeleted: false,
        query: {
          filter: {
            hidden: {
              $ne: true,
            },
            type: {
              $in: ['APPOINTMENT', 'CLASS'],
            },
          },
          sort: [
            {
              fieldName: 'name',
              order: 'ASC',
            },
          ],
          paging: { offset: 0, limit: 1 },
        },
      },
    );
    responseServices = await response.data.services;
  } catch (e) {
    console.error('Catalog data error: ', e);
  }
  return responseServices;
};

export const populateServiceData = (
  $w,
  flowAPI,
  service,
  businessLocaleData,
  props,
  biLogger,
) => {
  const t = flowAPI.translations.t;
  // console.log('UOU | populateServiceData with:', service);
  if (service?.media?.mainMedia === undefined) {
    // console.log('UOU | No image in service, removing image');
    $w(components.serviceImage).collapse();
    $w(components.imageC).collapse();
  } else {
    // console.log('UOU | Updating image in service');
    $w(components.serviceImage).setImage(service.media.mainMedia.image.url);
    $w(components.serviceImage).expand();
    $w(components.imageC).expand();
  }

  service.conferencing.enabled
    ? $w(components.vcBadge).expand()
    : $w(components.vcBadge).collapse();
  $w(components.serviceName).text = service.name;

  if (!flowAPI.environment.isSSR) {
    $w(
      components.serviceName,
    ).html = `<h1> <a href="${service.urls.servicePage.url.replace(
      '-statics',
      '',
    )}" target="_self" ; text-align:center">${service.name}</a> </h1>`;
  }

  $w(components.timezone).text = timezoneToShow(businessLocaleData);
};

const setDurationAndPrice = (isSSR, service, userLocale, repeaterData) => {
  let durationText, priceText, textToDisplay;
  priceText = `${configureServicePrice(isSSR, service, userLocale)}`;
  // console.log('UOU | priceText:', priceText);
  if (service?.type.includes('APPOINTMENT')) {
    // console.log('UOU | Calculating service price for APPOINTMENT');
    durationText = `${calculateServiceDuration(service, [])}`;
    // console.log('UOU | durationText:', durationText);
    textToDisplay = `${durationText} • ${priceText}`;
  } else {
    // console.log(
    //   'UOU | Duration for non appointment services is configured by availability',
    // );
    if (repeaterData.length === 0) {
      textToDisplay = `${priceText}`;
    } else {
      durationText = `${calculateServiceDuration(service, repeaterData)}`;
      textToDisplay = `${durationText} • ${priceText}`;
    }
  }
  return textToDisplay;
};

export const populateWidgetData = (
  $w,
  flowAPI,
  service,
  repeaterData,
  userLocale,
  biLogger,
) => {
  const t = flowAPI.translations.t;
  // console.log('UOU | populateWidgetData with service:', service);
  // console.log('UOU | populateWidgetData with repeaterData:', repeaterData);
  $w(components.serviceDurationAndPrice).text = setDurationAndPrice(
    flowAPI.environment.isSSR,
    service,
    userLocale,
    repeaterData,
  );
  // console.log('UOU | populateAvailabilityData repeaterData', repeaterData);

  if (!flowAPI.environment.isSSR) {
    if (repeaterData.length === 0) {
      // console.log('UOU | Setting empty state');
      $w(components.noFutureAvailabilityText).text = t(
        'app.widget.noServiceAvailability',
      );
      $w(components.availabilityStateBox).changeState('empty');
      biReportWidgetLoadedEmpty(biLogger, flowAPI.environment);
    } else {
      $w(components.seeMoreDatesAndTimes).text = t(
        'app.widget.seeMoreDatesAndTimes',
      );
      $w(components.seeMoreDatesAndTimes).link =
        service.urls.calendarPage.url.replace('-statics', '') +
        `?timezone=${repeaterData[0].slots[0].timezone}`;
      // console.log('UOU | Done setting service data');
      $w(components.availabilityStateBox).changeState('service');
      $w(components.mainRepeater).setRepeaterData(
        repeaterData,
        businessLocaleData,
      );
    }
  } else {
    $w(components.availabilityStateBox).changeState('loader');
  }
};

export const prepareRepeaterData = (
  availability,
  numberOfSlots,
  businessLocaleData,
) => {
  const availabilityEntries = availability.availabilityEntries;
  const repeaterArray: any[] = [];
  let arrayOfKeys: string[] = [];
  const mapOfSlots = new Map();
  if (availabilityEntries.length !== 0) {
    for (const availabilityEntry of availabilityEntries) {
      const slot = availabilityEntry.slot;
      if (arrayOfKeys.length <= numberOfSlots) {
        if (arrayOfKeys.includes(slot.startDate)) {
          mapOfSlots.get(slot.startDate).push(slot);
          arrayOfKeys = Array.from(mapOfSlots.keys());
        } else if (arrayOfKeys.length !== numberOfSlots) {
          mapOfSlots.set(slot.startDate, [slot]);
          arrayOfKeys = Array.from(mapOfSlots.keys());
        } else {
          break;
        }
      } else {
        break;
      }
    }
  } else {
    // console.log('UOU | No slots to display');
  }
  // console.log('UOU | mapOfSlots before return:', mapOfSlots);
  mapOfSlots.forEach((value, key) => {
    // console.log('UOU | value, key:', value, key);
    const slotDate = new Date(key).toLocaleDateString('en-US', {
      weekday: 'long',
      day: 'numeric',
      month: 'long',
      timeZone:
        businessLocaleData.preferredLocale === 'business'
          ? businessLocaleData.businessLocale
          : businessLocaleData.userLocale,
    });
    // console.log('UOU | slotDate:', slotDate);
    // console.log('UOU | repeaterArray:', repeaterArray);
    const selectedObject = repeaterArray.find((element) =>
      element.date.toString().includes(slotDate),
    );
    // console.log('UOU | selectedObject:', selectedObject);
    const objectIndex = repeaterArray.indexOf(selectedObject);
    // console.log('UOU | Index:', objectIndex);

    if (
      selectedObject !== undefined &&
      repeaterArray.indexOf(selectedObject) !== -1
    ) {
      value.forEach((slot) => {
        repeaterArray[objectIndex].slots.push(slot);
      });
    } else {
      repeaterArray.push({
        _id: repeaterArray.length.toString(),
        date: slotDate,
        slots: value,
      });
    }
  });
  // console.log('UOU | repeaterArray:', repeaterArray);
  return repeaterArray;
};

export const configureServicePrice = (isSSR, service, locale): String => {
  let servicePrice;
  switch (service.payment.rateType) {
    case 'FIXED':
      servicePrice = `${getCurrencySymbol(
        isSSR ? 'en-US' : locale,
        service.payment.fixed.price.currency,
      )}${service.payment.fixed.price.value}`;
      servicePrice =
        service.payment.pricingPlanIds.length === 0
          ? servicePrice
          : servicePrice + ' / Pricing plan';
      break;
    case 'NO_FEE':
      servicePrice =
        service.payment.pricingPlanIds.length === 0 ? 'Free' : 'Pricing plan';
      break;
    case 'VARIED':
      servicePrice = `From ${getCurrencySymbol(
        isSSR ? 'en-US' : locale,
        service.payment.varied.minPrice.currency,
      )}${service.payment.varied.minPrice.value}`;
      break;
    case 'CUSTOM':
      servicePrice = service.payment.custom.description;
      break;
  }
  // console.log('UOU | Service price:', servicePrice);
  return servicePrice;
};

export const calculateServiceDuration = (service, repeaterData): String => {
  let minutes;
  if (service?.type.includes('APPOINTMENT')) {
    // console.log('UOU | Calculating price for APPOINTMENT:', service);
    minutes = service?.schedule.availabilityConstraints.sessionDurations[0];
    // console.log('UOU | minutes:', minutes);
  } else {
    const milis =
      new Date(repeaterData[0].slots[0].endDate).getTime() -
      new Date(repeaterData[0].slots[0].startDate).getTime();
    const seconds = Math.floor(milis / 1000);
    minutes = Math.floor(seconds / 60);
  }
  const minutesRemainder = minutes % 60;
  const hours = Math.floor(minutes / 60);
  if (hours !== 0 && minutesRemainder === 0) {
    return `${hours} hr`;
  } else if (hours !== 0 && minutesRemainder !== 0) {
    return `${hours} hr ${minutesRemainder} min`;
  } else {
    return `${minutesRemainder} min`;
  }
};
export const getCurrencySymbol = (locale, currency) => {
  return (0)
    .toLocaleString(locale, {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, '')
    .trim();
};

const calculateNumberOfSlotsFromMainRepeaterData = (
  mainRepeaterArray: any[],
) => {
  const arrayOfSlotTimes: string[] = [];
  if (mainRepeaterArray.length !== 0) {
    mainRepeaterArray.forEach((day) => {
      const daySlots = day.slots;
      // console.log('UOU | daySlots:', daySlots);
      daySlots.forEach((slotInDay) => {
        if (!arrayOfSlotTimes.includes(slotInDay.startDate)) {
          arrayOfSlotTimes.push(slotInDay.startDate);
        }
      });
    });
  }
  // console.log('UOU | Slots for BI:', arrayOfSlotTimes.length);
  return arrayOfSlotTimes.length;
};
